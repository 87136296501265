<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-multiple
      </v-icon> Account Members
    </h2>
    <br>
    <v-alert
      v-if="!roleCheck('userAdmin')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="alert-style"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="membersList"
        :search="search"
        disable-sort
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template #item="{ item }">
          <tr>
            <td>{{ item.first_name }} {{ item.last_name }}</td>
            <td>
              <span v-if="item.phone_number !== null">
                {{ item.phone_number }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="item.email !== null">
                {{ item.email }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="item.organization !== null">
                {{ item.organization.name }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td>
              <v-chip
                v-if="item.role !== null"
                outlined
                small
                class="ml-2"
                color="#37474F"
                dark
              >
                {{ item.role.name }}
              </v-chip>
              <span v-else>N/A</span>
            </td>
            <td>
              {{ item.created_at | convertToLocal }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>

<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';

  export default {
    name: 'MembersList',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        loading: false,
        headers: [
          { text: 'Name', align: 'start', value: 'first_name' },
          { text: 'Phone', value: 'phone_number' },
          { text: 'Email', value: 'email' },
          { text: 'Company', value: 'organization.name' },
          { text: 'Role', value: 'role.name' },
          { text: 'Added On', value: 'created_at' },
        ],
      };
    },
    computed: {
      membersList () {
        return this.$store.getters['members/getMembersList'];
      },
    },
    async mounted () {
      this.roleCheck('userAdmin') && await this.getMembersList();
    },
    methods: {
      async getMembersList () {
        this.loading = true;
        await this.$store.dispatch('members/fetchMembersList').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC;
}
.v-data-table {
  background-color: #ECEFF1
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
